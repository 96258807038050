var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cc-card"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"margin-left":"-15px","margin-top":"-20px"},attrs:{"x-small":"","elevation":"0","fab":"","dark":"","color":"error"}}),(_vm.data.LlpBalancePartition !== null)?_c('v-btn',{staticStyle:{"z-index":"2","transform":"rotateZ(270deg)","margin-top":"50px","margin-left":"90px","position":"absolute","width":"133px","border-radius":"20px"},attrs:{"color":_vm.data.LlpBalancePartition == 1
              ? 'warning'
              : _vm.data.LlpBalancePartition == 2
              ? 'error'
              : _vm.data.LlpBalancePartition == 3
              ? 'success'
              : ''}},[_vm._v(_vm._s(_vm.data.LlpBalancePartition == 1 ? "Partición" : _vm.data.LlpBalancePartition == 2 ? "Union" : _vm.data.LlpBalancePartition == 3 ? "Verde Proceso." : ""))]):_vm._e(),_c('h3',[_vm._v("PA - "+_vm._s(_vm.data.PprID)+" "),_c('br')]),_c('p',{staticStyle:{"-webkit-text-stroke":"thick","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.data.PprQuantityJaba)+" Jabas - "+_vm._s(_vm.data.PprWeightNet)+" ")]),_c('b',[_vm._v(_vm._s(_vm.data.TypeFrozenCategoryName))]),_c('br'),_c('b',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(_vm.data.TypeCropName)+", "+_vm._s(_vm.data.TypeCultiveName)+" - "+_vm._s(_vm.data.VrtName))]),_c('br'),(_vm.data.LlpIDDetail !== null)?_c('b',{staticStyle:{"font-size":"10px"}},[_c('v-chip',{attrs:{"small":""}},[_vm._v("PA - "+_vm._s(_vm.data.LlpIDDetail))])],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"z-index":"2","transform":"rotateZ(0deg)","margin-top":"-15px","margin-left":"40px","position":"absolute","width":"","border-radius":"20px"},attrs:{"x-small":"","color":"info"}},[_vm._v(_vm._s(_vm.data.LlpID))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }