<template>
  <div class="cc-card">
    <v-row>
      <v-col cols="12">
        <v-btn
          x-small
          elevation="0"
          fab
          dark
          color="error"
          style="margin-left: -15px; margin-top: -20px"
        >
          <!-- <v-icon style="font-size: 16px !important"
				>fas fa-eye</v-icon
			> -->
        </v-btn>
        <v-btn
          :color="
            data.LlpBalancePartition == 1
              ? 'warning'
              : data.LlpBalancePartition == 2
              ? 'error'
              : data.LlpBalancePartition == 3
              ? 'success'
              : ''
          "
          v-if="data.LlpBalancePartition !== null"
          style="
            z-index: 2;
            transform: rotateZ(270deg);
            margin-top: 50px;
            margin-left: 90px;
            position: absolute;
            width: 133px;
            border-radius: 20px;
          "
          >{{
            data.LlpBalancePartition == 1
              ? "Partición"
              : data.LlpBalancePartition == 2
              ? "Union"
              : data.LlpBalancePartition == 3
              ? "Verde Proceso."
              : ""
          }}</v-btn
        >

        <!-- <v-btn small color="info"  style="z-index: 2;transform: rotateZ(270deg);margin-top: 70px;margin-left: 90px;position: absolute;width: 133px;border-radius: 20px;">Union</v-btn> -->
        <!-- <v-btn
			x-small
			elevation="0"
			fab
			dark
			color="success"
			style="margin-left: 120px; margin-top: -20px;"
			@click="viewSendProccess()"
		>
			<i class="fas fa-share-square"></i>
		</v-btn> -->

        <h3>PA - {{ data.PprID }} <br /></h3>
        <p style="-webkit-text-stroke: thick; font-size: 12px">
          {{ data.PprQuantityJaba }} Jabas - {{ data.PprWeightNet }}
        </p>
        <b>{{ data.TypeFrozenCategoryName }}</b>
        <br />
        <b style="font-size: 10px"
          >{{ data.TypeCropName }}, {{ data.TypeCultiveName }} -
          {{ data.VrtName }}</b
        >
        <br />
        <b style="font-size: 10px" v-if="data.LlpIDDetail !== null"
          ><v-chip small>PA - {{ data.LlpIDDetail }}</v-chip>
        </b>
      </v-col>
      <v-col cols="12">
        <v-btn
          x-small
          color="info"
          style="
            z-index: 2;
            transform: rotateZ(0deg);
            margin-top: -15px;
            margin-left: 40px;
            position: absolute;
            width: ;
            border-radius: 20px;
          "
          >{{ data.LlpID }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: { data: null },
  methods: {
    viewMovimients() {
      this.$emit("viewMovimients", this.data);
    },
    viewSendProccess() {
      this.$emit("viewSendProccess", this.data);
    },
  },
  created() {
    console.log("RECIBIENTO DDAT", this.data);
  },
};
</script>
<style>
.cc-card {
  padding: 10px;
  text-align: left;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin: 5px;
  background-color: white;
  min-width: 200px;
  font-size: 1em;
}

.cc-card h3 {
  margin: 0px;
}
</style>