<template>
  <div>
    <v-card height="250">
      <v-card-text>
        <b>Despacho de cámara</b>
      </v-card-text>
      <v-row style="margin-top: 50px">
        <v-col>
          <s-scanner-qr
            :config="configScann"
            autofocus
            return-object
            @onValue="onValue($event)"
            @onData="onData($event)"
            :readonly="false"
          >
          </s-scanner-qr>
        </v-col>
      </v-row>
    </v-card>

    <!-- ventana modal de registro y posisicones -->
    <v-dialog v-if="openModal" v-model="openModal" fullscreen>
      <v-card>
        <s-toolbar
          dark
          save
          color="primary"
          close
          @close="openModal = false"
          label="Registro de posiciones en embarque"
        >
        </s-toolbar>
        <v-container>
          <v-row style="margin-top: 10px; margin-bottom: 10px">
            <h2>Registro de embarque</h2>
          </v-row>
          <v-row>
            <v-col>
              <s-text label="N° Precinto"></s-text>
            </v-col>
            <v-col>
              <s-text label="N° Embarque"></s-text>
            </v-col>
            <v-col>
              <s-text label="N° OV"></s-text>
            </v-col>
            <v-col>
              <s-text label="N° Contenedor"></s-text>
            </v-col>
          </v-row>
          <v-row style="margin: auto">
            <v-col cols="12">
              <v-card flat>
                <v-row>
                  <v-col align="center" cols="12" lg="12" md="12">
                    <drag-drop
                      :dropzones="itemsPositionAll"
                      :originalData="dataPrihuela"
                      :dropzonesTitle="'POSICIÓN DE CÁMARA'"
                      :inPlace="true"
                      :hidden="false"
                    >
                      <template #dd-card="{ cardData }">
                        <card-dispatch
                          :data="cardData"
                          @viewMovimients="viewMovimients($event)"
                          @viewSendProccess="viewSendProccess($event)"
                        >
                        </card-dispatch>
                      </template>
                    </drag-drop>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <!-- posiciones de la cámara -->
      <!-- <v-card dense> </v-card> -->
    </v-dialog>
  </div>
</template>

<script>

import CardDispatch from "./CardDispatch.vue";

export default {
  props: {},
  components: {
    CardDispatch,
  },
  data() {
    return {
      configScann: {},
      openModal: false,
    };
  },

  methods: {

    // getData(){
    //   _sDesinfectedPalletService.list(this.$fun.getUserID())
    //   .then((r)=>{
    //     console.log('datos obtenidos',r.data);
    //     this.dataPrihuela = r.data;
    //     if(this.dataPrihuela.length > 0){
    //       this.generatedPositions();
    //     }else{
    //       for (var i = 0; i < 100 ; i++) {
    //         var objrrr = { id: i + 1, name: "Posición 0" + (i + 1), children: [] };
    //         this.itemsPositionAll.push(objrrr);
    //       }
    //     }
    //   })
    // },

    // generatedPositions() {
    //   for (var i = 0; i < 100 ; i++) {
    //     var objrrr = { id: i + 1, name: "Posición 0" + (i + 1), children: [] };
    //     this.itemsPositionAll.push(objrrr);
    //   }

    //   this.itemsPositionAll.forEach((element) => {
    //     element.children = [];
    //     var obkkk = this.dataPrihuela.find((x) => x.PprPosition == element.id);  // color el numero de registros

    //     if (obkkk !== undefined) {
    //       element.children.push(obkkk);

    //       this.dataPrihuela = this.dataPrihuela.filter(
    //         (x) => x.PprID != obkkk.PprID
    //       );
    //     }
    //   });

    //   console.log('posiciones',this.itemsPositionAll)
    // },

    onData(val) {},

    onValue(val) {
      if (val == "Sin datos" || isNaN(val)) {
        this.$fun.alert("El QR leido no contiene datos", "warning");
      } else {
        this.openModal = true;
        // almacenamos el dato
      }
    },
  },

  created() {
    this.getData();
  },
};
</script>